/**
 * Srdce pro vás - carousel // GlideJS
 *
 */
const dynamicSPVOrganizationsCarousel = async () => {
    const orgCarousel = document.querySelector('[data-spv-organizations-carousel]');

    if (orgCarousel) {
        const { organizationsCarousel } = await import(
            /* webpackChunkName: 'chunk-srdce-pro-vas-organizations-carousel' */
            './modules/srdce-pro-vas/organizations-carousel'
        );

        organizationsCarousel();
    }
};

/**
 * Srdce pro vás - seznam organizací // Vue.js 2
 *
 */
const dynamicSPVOrganizations = async () => {
    const org = document.querySelector('[data-spv-organizations]');

    if (org) {
        const { organizations } = await import(
            /* webpackChunkName: 'chunk-srdce-pro-vas-organizations' */
            './modules/srdce-pro-vas/organizations'
        );

        organizations();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicSPVOrganizations();
    dynamicSPVOrganizationsCarousel();
};

loadDynamicImports();
